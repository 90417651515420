<template>
  <div>
    <div class="y-desc" style="margin-bottom: 10px">启动标记用户编号：截止当前最后访问的分享链接所属用户编号</div>
    <el-card style="min-height: 45rem">
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="uid" label="关联用户编号"></el-table-column>
        <el-table-column label="关联用户信息" width="200" show-overflow-tooltip>
          <template #default="s">
            <div v-if="s.row.user" class="flex-def">
              <img v-if="s.row.user.avatar" :src="s.row.user.avatar | tomedia"
                   style="width: 3rem;height: 3rem;border-radius: 2rem" alt="">
              <div style="margin-left: .5rem">
                <div>{{ s.row.user.nickname }}</div>
                <div>{{ s.row.user.phone }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="openid" label="openid"></el-table-column>
        <el-table-column prop="last_launch_from_uid" label="启动标记用户编号"></el-table-column>
        <el-table-column label="记录时间">
          <template v-slot="s">
            {{s.row.created_at | date}}
          </template>
        </el-table-column>
        <el-table-column label="更新时间">
          <template v-slot="s">
            {{s.row.updated_at | date}}
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>


export default {
  name: "mini",
  components: {

  },

  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm: {

      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    searchGo() {
      this.page = 1;
      this.list.length = 0;
      this.load();
    },
    searchReset() {
      this.searchForm = {

      }
      this.searchGo()
    },
    load() {
      this.$u.api.user.visitorMini({
        page: this.page,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>